<template>
  <svg
    @click="$emit('click')"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.59929 17.2662C6.92838 17.2662 7.19516 17.5344 7.19516 17.8653V23.3994C7.19516 23.411 7.19483 23.4227 7.19415 23.4343C7.18328 23.6214 7.20948 23.8089 7.27125 23.9858C7.33302 24.1628 7.42916 24.3258 7.55418 24.4654C7.67921 24.605 7.83064 24.7185 7.99979 24.7992C8.16493 24.878 8.34373 24.924 8.52632 24.9347H22.5163C22.8804 24.9023 23.2179 24.7302 23.4584 24.4543C23.7033 24.1733 23.8279 23.8073 23.8057 23.4352C23.805 23.4233 23.8046 23.4113 23.8046 23.3994V17.8653C23.8046 17.5344 24.0714 17.2662 24.4005 17.2662C24.7296 17.2662 24.9964 17.5344 24.9964 17.8653V23.3826C25.032 24.0631 24.8019 24.7309 24.3547 25.2439C23.9035 25.7617 23.2675 26.0803 22.5844 26.1312C22.5697 26.1323 22.555 26.1329 22.5403 26.1329H8.51049C8.50079 26.1329 8.49109 26.1326 8.4814 26.1322C8.13736 26.1153 7.80002 26.0301 7.48886 25.8817C7.1777 25.7332 6.89885 25.5244 6.66847 25.2671C6.43809 25.0098 6.26074 24.7092 6.14674 24.3826C6.03478 24.0619 5.98611 23.7224 6.00341 23.383V17.8653C6.00341 17.5344 6.27019 17.2662 6.59929 17.2662Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5 5.86621C15.8498 5.86621 16.1333 6.14976 16.1333 6.49954V20.4329C16.1333 20.7827 15.8498 21.0662 15.5 21.0662C15.1502 21.0662 14.8667 20.7827 14.8667 20.4329V6.49954C14.8667 6.14976 15.1502 5.86621 15.5 5.86621Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6044 19.6457C15.0991 20.1404 15.9011 20.1404 16.3957 19.6457L22.3898 13.6516C22.6371 13.4042 23.0381 13.4042 23.2854 13.6516C23.5328 13.8989 23.5328 14.2999 23.2854 14.5472L16.3957 21.4371C15.9011 21.9317 15.0991 21.9317 14.6044 21.4371L7.7145 14.5472C7.46717 14.2999 7.46717 13.8989 7.7145 13.6516C7.96183 13.4042 8.36284 13.4042 8.61017 13.6516L14.6044 19.6457Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'DownloadIcon',
  mixins: [IconsMixin]
};
</script>
