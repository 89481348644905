<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    aria-label="Close icon"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.439341 0.43934C1.02513 -0.146447 1.97488 -0.146447 2.56066 0.43934L23.5607 21.4393C24.1464 22.0251 24.1464 22.9749 23.5607 23.5607C22.9749 24.1464 22.0251 24.1464 21.4393 23.5607L0.439341 2.56066C-0.146445 1.97487 -0.146445 1.02513 0.439341 0.43934Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.5607 0.43934C24.1464 1.02513 24.1464 1.97487 23.5607 2.56066L2.56066 23.5607C1.97487 24.1464 1.02513 24.1464 0.43934 23.5607C-0.146447 22.9749 -0.146447 22.0251 0.43934 21.4393L21.4393 0.43934C22.0251 -0.146447 22.9749 -0.146447 23.5607 0.43934Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgClose',
  props: {
    fill: {
      type: String,
      default: '#a4a4a4'
    }
  }
};
</script>
