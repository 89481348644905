<template>
  <div class="view-wrapper">
    <FullCalendarWrapper
      :events="eventsByViewType"
      @change-view-type="changeView"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import { formatCalendarEvents } from '../helpers/events.helper';
import FullCalendarWrapper from './Calendar/FullCalendarWrapper.vue';

export default {
  name: 'EventsCalendarView',
  components: {
    FullCalendarWrapper,
  },
  data() {
    return {
      viewType: undefined,
    };
  },
  computed: {
    ...mapState('events', ['allEvents', 'sections', 'selectedCalendars']),
    ...mapGetters({
      headingColor: 'getThemeHeadingColor',
    }),
    eventsByViewType() {
      if (!this.allEvents?.length) {
        return [];
      }
      return this.allEvents.reduce(formatCalendarEvents(this.viewType), []);
    },
    colorOverrides() {
      const color = this.headingColor;
      if (!color) {
        return {};
      }

      return {
        '--button-outline': color,
        // Primary
        '--button-primary-bg': color,
        '--button-primary-bg-hover': color,
      };
    },
  },
  mounted() {
    if (isEmpty(this.selectedCalendars)) {
      this.$store.commit(
        'events/initSelectedCalendars',
        this.sections.flatMap((item) => item.id),
      );
    }
  },
  methods: {
    changeView(type) {
      this.viewType = type;
    },
  },
};
</script>

<style lang="scss" scoped>
.view-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  .view-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;

    .view-header-left,
    .view-header-right {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
