<template>
  <div class="event-list-date">
    <div class="month">
      {{ month }}
    </div>
    <div class="day">
      {{ day }}
    </div>
    <div class="hour">
      <p class="start">
        {{ start }}
      </p>
      <p
        v-if="!isAllDay"
        class="end"
      >
        {{ end }}
      </p>
    </div>
  </div>
</template>

<script>
import { formatEventTime, formatEventDay, formatEventMonth } from '../../helpers/events.helper';

export default {
  name: 'EventListDate',
  props: {
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    isAllDay: Boolean,
    isMulti: Boolean,
  },
  computed: {
    date() {
      return this.isMulti ? this.endDate : this.startDate;
    },
    month() {
      return this.$t(`events.labelMonths.${formatEventMonth(this.date)}`);
    },
    day() {
      return formatEventDay(this.date, this.$intl.locale);
    },
    start() {
      if (this.isAllDay) {
        return 'All Day';
      }
      return this.startDate && formatEventTime(this.startDate, this.$intl.locale);
    },
    end() {
      return this.endDate && formatEventTime(this.endDate, this.$intl.locale);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../global/scss/mixins/flex_mixin';

.event-list-date {
  @include flex-column;
  text-transform: uppercase;

  .month {
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 20px;
    text-align: center;
  }

  .day {
    font-style: normal;
    font-weight: bold;
    font-size: 2em;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    margin-bottom: 8px;
  }

  .hour {
    font-style: normal;
    font-weight: normal;
    font-size: 0.7em;
    line-height: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
