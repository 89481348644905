<template>
  <div class="toolbar-header">
    <div class="toolbar-header-left">
      <div class="header-title">
        {{ headerText }}
      </div>
      <div class="mobile-actions">
        <a :href="downloadAllEvents">
          <DownloadIcon />
          <span class="sr-only">{{ $t('events.downloadCalendar') }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DownloadIcon from './icons/DownloadIcon.vue';

export default {
  name: 'EventsToolbarHeader',
  components: { DownloadIcon },
  computed: {
    ...mapState('events', ['searchText', 'queryParams', 'allEvents']),
    headerText() {
      if (this.searchText) {
        return this.allEvents.length > 0
          ? this.$t('events.resultsForSearch', { searchQuery: this.searchText })
          : this.$t('events.noResultsForSearch', { searchQuery: this.searchText });
      }
      return this.$t('events.title');
    },
    downloadAllEvents() {
      const { section_ids = '', filter_ids = '' } = this.queryParams;
      const { url } = this.$store.getters.getEventsApi(useRuntimeConfig().public.AXIOS_BROWSER_BASE_API_URL);
      return `${url}/generate_ical?filter_ids${filter_ids ? '=' : ''}${filter_ids}&section_ids${
        section_ids ? '=' : ''
      }${section_ids}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../global/scss/mixins/breakpoints.scss';

@include screen('>=xs') {
  .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .toolbar-header {
    margin-top: 32px;
    .toolbar-header-left,
    .toolbar-header-right {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
  .header-title {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 1.5px;
    margin-right: 8px;
  }
  .mobile-actions {
    display: flex;
    align-items: center;
    margin-left: 5px;
    button {
      background: none;
      border: none;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
@include screen('xs') {
  .toolbar-header {
    .toolbar-header-left {
      .header-title {
        margin-right: 0;
      }
      .view-header {
        margin-right: 0;
      }
    }
  }
}
</style>
