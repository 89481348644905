<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 12H24V11H8V12ZM9.99999 15H22V14H9.99999V15ZM20 18H12V17H20V18ZM14 21H18V20H14V21Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'FilterIcon',
  mixins: [IconsMixin]
};
</script>
