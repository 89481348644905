<template>
  <div class="event-filters">
    <div class="search-box">
      <div>
        <label
          for="event-filter-search"
          class="sr-only"
          >{{ $t('events.initialSearchAriaLabel') }}</label
        >
        <SearchTag
          v-if="searchTagText"
          :text="searchTagText"
          :on-remove="onRemoveTag"
        />
        <TextInput
          v-else
          id="event-filter-search"
          v-model="searchVal"
          type="search"
          :placeholder="$t('events.searchPlaceholder')"
          width="100%"
          @update:model-value="searchDebounce"
        />
        <div
          v-if="searchVal"
          class="sr-only"
          aria-live="polite"
        >
          {{
            $t('events.searchResultsAriaLabel', {
              upcomingEvents: totalUpcomingEvents,
              pastEvents: totalPastEvents,
            })
          }}
        </div>
      </div>
      <div>
        <img
          src="../../../assets/search-icon.svg"
          class="search-icon"
          alt=""
        />
      </div>
    </div>
    <a
      v-if="currentView === 'EventsListView' || currentView === 'EventsCalendarView'"
      href="#"
      class="skip-to-calendar"
      @click.prevent="$emit('move-focus')"
    >
      {{ $t('events.skipToCalendar') }}
    </a>
    <div v-if="sections && sections.length > 1">
      <h3 class="title">
        {{ $t('events.calendars') }}
        <label class="sr-only">{{ $t('events.submit') }}</label>
        <button
          class="arrow"
          :class="{ 'upside-down': !showCalendars }"
          @click="toggleCalendars"
        >
          <DropdownArrowIcon :aria-label="$t('events.collapseCalendarSectionAriaLabel')" />
        </button>
      </h3>
      <div
        v-show="showCalendars"
        class="block"
      >
        <FormCheckbox
          v-for="{ id, name } of sections"
          :key="`${id}-${name}`"
          :checkbox-id="`${id}-${name}`"
          class="filter-checkbox"
          :model-value="isChecked(EVENT_TYPE.CALENDAR, id)"
          @changed="checkboxChange(EVENT_TYPE.CALENDAR, $event, id, name)"
        >
          {{ name }}
        </FormCheckbox>
      </div>
    </div>

    <div
      v-for="(eventFilters, name) in formattedFilters"
      :key="name"
    >
      <h3 class="title">
        {{ name }}
        <button
          class="arrow"
          :class="{ 'upside-down': !selectedEventFilters.includes(name) }"
          @click="toggleFilters(name)"
        >
          <DropdownArrowIcon :aria-label="getCollapseAriaLabel(name)" />
        </button>
        <label class="sr-only">{{ $t('events.submit') }}</label>
      </h3>
      <div
        v-show="selectedEventFilters.includes(name)"
        class="block"
      >
        <span class="filters-applied">
          {{ selectedFiltersText(eventFilters) }}
        </span>
        <FormCheckbox
          v-for="{ id, name: tagName } of eventFilters"
          :key="`${id}-${tagName}`"
          :checkbox-id="`${id}-${tagName}`"
          class="filter-checkbox"
          :model-value="isChecked(EVENT_TYPE.FILTER, id)"
          @changed="checkboxChange(EVENT_TYPE.FILTER, $event, id, tagName)"
        >
          {{ tagName }}
        </FormCheckbox>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import { getSpecificListOrCalView } from '../components/helpers/events.helper';
import FormCheckbox from '../../global/Forms/FormCheckbox.vue';
import DropdownArrowIcon from './icons/DropdownArrowIcon.vue';
import TextInput from '../../global/Forms/TextInput';
import SearchTag from './EventViews/shared/SearchTag.vue';

const EVENT_TYPE = Object.freeze({
  CALENDAR: 'selectedCalendar',
  FILTER: 'selectedFilter',
});

export default {
  name: 'EventsFiltersSelectors',
  components: {
    TextInput,
    FormCheckbox,
    DropdownArrowIcon,
    SearchTag,
  },
  emits: ['move-focus'],
  data() {
    return {
      EVENT_TYPE,
      searchTimer: null,
      searchDebounce: debounce(this.searchCall, 500),
      searchVal: '',
      selectedEventFilters: [],
      showCalendars: true,
    };
  },
  computed: {
    ...mapState('events', [
      'totalUpcomingEvents',
      'totalPastEvents',
      'selectedCalendars',
      'selectedFilters',
      'filters',
      'currentView',
      'queryParams',
      'hasSections',
      'searchTagText',
      'searchText',
      'lastView',
      'lastFocusedElem',
      'sections',
      'searchText',
    ]),
    formattedFilters() {
      const formatted = {};
      this.filters.forEach((e) => {
        if (formatted[e.custom_section_name]) {
          formatted[e.custom_section_name].push(e);
        } else {
          formatted[e.custom_section_name] = [e];
        }
      });

      return formatted;
    },
  },
  created() {
    this.searchVal = this.searchText;
    this.selectedEventFilters.push(...Object.keys(this.formattedFilters));
  },
  methods: {
    onRemoveTag() {
      const today = new Date();
      this.$store.commit('events/setEventSearchTagText', '');
      this.$store.dispatch('events/updateDateFilters', {
        day: today.getDate(),
        month: today.getMonth(),
        year: today.getFullYear(),
      });
      return navigateTo({
        query: {
          id: undefined,
        },
      });
    },
    checkboxChange(type, { checked }, value, tagName) {
      if (type === EVENT_TYPE.CALENDAR) {
        this.$store.dispatch('events/updateCalendars', {
          checked,
          value,
          target: `${value}-${tagName}`,
        });
      } else {
        this.$store.dispatch('events/updateFilters', {
          checked,
          value,
          target: `${value}-${tagName}`,
        });
      }

      if (this.searchTagText) {
        const today = new Date();
        this.$store.commit('events/setEventSearchTagText', '');
        this.$store.dispatch('events/updateDateFilters', {
          day: today.getDate(),
          month: today.getMonth(),
          year: today.getFullYear(),
        });
      }

      let params = {};
      if (this.searchText) {
        params = {
          section_ids: this.queryParams.section_ids ? this.queryParams.section_ids : undefined,
          filter_ids: this.queryParams.filter_ids ? this.queryParams.filter_ids : undefined,
          search: this.searchText,
        };
      } else {
        params = {
          ...this.queryParams,
          view: getSpecificListOrCalView(this.$store.state.events),
        };
      }
      this.$store.commit('events/setQueryParams', params);
      return navigateTo({
        query: {
          ...params,
        },
      });
    },
    isChecked(type, value) {
      if (type === EVENT_TYPE.CALENDAR) {
        return !!this.selectedCalendars.find((id) => parseInt(id) === parseInt(value));
      }
      return !!this.selectedFilters.find((id) => parseInt(id) === parseInt(value));
    },
    toggleCalendars() {
      this.showCalendars = !this.showCalendars;
    },
    toggleFilters(name) {
      if (this.selectedEventFilters.includes(name)) {
        this.selectedEventFilters = this.selectedEventFilters.filter((filter) => filter !== name);
      } else {
        this.selectedEventFilters.push(name);
      }
    },
    selectedFiltersText(section) {
      let amount = 0;

      section.forEach((e) => {
        if (this.selectedFilters.includes(e.id)) {
          amount += 1;
        }
      });

      return this.$t('events.amountOfTagsApplied', {
        n: amount,
        amount: this.$intl.formatNumber(amount),
      });
    },
    getCollapseAriaLabel(calendarName) {
      return this.$t('events.collapseCalendarNameSectionAriaLabel', {
        calendarName,
      });
    },
    getCalendarCheckboxAriaLabel(calendarName) {
      return this.$t('events.calendarCheckboxAriaLabel', {
        calendarName,
      });
    },
    getTagNameCheckboxAriaLabel(tagName, calendarName) {
      return this.$t('events.calendarTagNameCheckboxAriaLabel', {
        calendarName,
        tagName,
      });
    },
    async searchCall() {
      await this.$store.dispatch('events/updateSearchText', this.searchVal);

      let queryParams = {};
      if (this.searchVal) {
        queryParams = {
          section_ids: this.queryParams.section_ids ? this.queryParams.section_ids : undefined,
          filter_ids: this.queryParams.filter_ids ? this.queryParams.filter_ids : undefined,
          search: this.searchVal,
        };
      } else {
        queryParams = {
          ...this.queryParams,
          search: undefined,
        };
      }
      navigateTo({
        query: queryParams,
      });
      setTimeout(() => {
        const searchBox = document.getElementById('event-filter-search');
        searchBox.focus();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../global/scss/mixins/breakpoints';
@import '../../global/scss/mixins/flex_mixin';
@import '../../global/scss/color_vars';

.event-filters {
  min-width: 220px;
  .search-box {
    margin-bottom: 32px;
  }

  .title {
    @include flex-row;
    justify-content: space-between;
    align-items: center;

    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    letter-spacing: 0.8px;
    text-transform: uppercase;

    color: #333333;
  }

  .block {
    border-left: 1px solid #00376c;
    padding: 0 16px;
    margin: 16px 0;
    @include flex-row;
    flex-wrap: wrap;

    & .filter-checkbox {
      flex-basis: 100%;
      padding: 0 3px;
    }
  }

  .search-icon {
    position: relative;
    top: -26px;
    float: right;
    right: 10px;
  }

  .bob:focus-within {
    --tab-font-color-active: #{$color-primary-dark};
    outline: var(--tab-font-color-active) auto 1px;
    box-shadow: 1px white;
    border-radius: 3px;
    padding: 1px;
  }

  .search-icon:focus {
    --tab-font-color-active: #{$color-primary-dark};
    outline: var(--tab-font-color-active) auto 1px;
    box-shadow: 1px white;
    border-radius: 3px;
    padding: 1px;
  }

  .filters-applied {
    font-size: 14px;
    color: #747474;
    margin-bottom: 6px;
  }

  .upside-down {
    transform: rotate(180deg);
  }

  .arrow {
    transition: transform 150ms ease;
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
}

:deep(.checkmark) {
  min-width: 16px !important;
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.focus {
  outline: none !important;
}

.skip-to-calendar {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  &:focus {
    width: auto;
    height: auto;
    background-color: #f1f1f1;
    border-radius: 3px;
    clip: auto;
    clip-path: none;
    color: #21759b;
    font-size: 14px;
    font-weight: bold;
    left: 5px;
    padding: 15px 23px 14px;
    top: 5px;
    text-align: center;
    z-index: 20;
  }
}
</style>
