<template>
  <div class="view-header">
    <div class="view-header-right">
      <span
        id="year-label"
        class="sr-only"
        >{{ $t('events.selectYear') }}</span
      >
      <button
        id="year-select"
        ref="year-select"
        aria-labelledby="year-label year-select"
        aria-haspopup="listbox"
        :aria-expanded="showDropdown.toString()"
        class="right-dropdown"
        @click.exact.stop.prevent="toggleDropdown()"
        @keydown.down.exact.stop.prevent="toggleDropdown()"
        @keydown.tab.exact="handleBlur()"
        @keydown.tab.shift.exact="handleBlur()"
      >
        <CalendarIcon
          class="right-dropdown-icon"
          aria-hidden="true"
          focusable="false"
        />
        <div class="right-dropdown-text">
          {{ selectedYear }}
          <DropdownArrowIcon v-if="showDropdownButton" />
        </div>
        <div
          v-show="showDropdown"
          v-click-outside="toggleDropdown"
          role="listbox"
          aria-labelledby="year select"
          class="right-dropdown-content"
        >
          <p
            v-for="year in YEARS"
            :ref="`year-${year}`"
            :key="year"
            :aria-selected="`${year === selectedYear}`"
            role="option"
            tabindex="-1"
            class="right-dropdown-content-item"
            @click.exact.stop.prevent="changeYear(year)"
            @keydown.enter.exact.prevent.stop="changeYear(year)"
            @keydown.tab.exact="handleBlur()"
            @keydown.down.exact.prevent.stop="arrowNavigation(year + 1)"
            @keydown.up.exact.prevent.stop="arrowNavigation(year - 1)"
            @keydown.tab.shift.exact="handleBlur()"
            @keydown.esc.exact="handleBlur()"
          >
            {{ year }}
          </p>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// Components
import CalendarIcon from '../../icons/CalendarIcon.vue';
import DropdownArrowIcon from '../../icons/DropdownArrowIcon.vue';

// TODO: How many years should we handle? - where should this be stored?
const YEARS = [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

export default {
  name: 'YearDropdown',
  components: { CalendarIcon, DropdownArrowIcon },
  data() {
    return {
      YEARS,
      showDropdown: false,
    };
  },
  computed: {
    ...mapState('events', ['queryParams', 'currentView', 'listViewType']),
    ...mapGetters('events', ['getCurrentYear']),
    showDropdownButton() {
      return !(this.listViewType === 'all' && this.currentView === 'EventsListView');
    },
    selectedYear() {
      return this.showDropdownButton ? this.getCurrentYear : new Date().getFullYear();
    },
  },
  methods: {
    toggleDropdown(forceClose = false) {
      if (forceClose || !this.showDropdownButton) {
        this.showDropdown = false;
        return;
      }
      this.showDropdown = !this.showDropdown;
      this.$nextTick(() => {
        if (this.$refs[`year-${this.getCurrentYear}`]) this.$refs[`year-${this.getCurrentYear}`][0].focus();
      });
    },
    changeYear(year) {
      this.$store.dispatch('events/updateDateFilters', {
        year,
        target: this.$refs['year-select'].id,
      });
      this.$store.commit('events/setSelectedYearUpdated', true);
      this.toggleDropdown(true);
      return navigateTo({
        query: {
          ...this.$route.query,
          ...this.queryParams,
        },
      });
    },
    handleBlur() {
      this.toggleDropdown(true);
    },
    arrowNavigation(year) {
      if (YEARS.indexOf(year) === -1) return;
      this.$refs[`year-${year}`][0].focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.view-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 24px;
  .view-header-left,
  .view-header-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .view-header-right {
    position: relative;
    cursor: pointer;
    .right-dropdown {
      display: flex;
      justify-content: center;
      background: none;
      border: none;
      cursor: pointer;
      align-items: center;
      .right-dropdown-text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.4px;
        margin-left: 8px;
        display: flex;
        align-items: center;
      }
      .right-dropdown-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        min-width: 70px;
        top: 40px;
        right: 0;
        cursor: pointer;
        background-color: #f9f9f9;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        padding: 12px 16px;
        z-index: 1;
        .right-dropdown-content-item {
          min-height: 30px;
        }
      }
    }
  }
}
</style>
