<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 17.5V18.5C18.5 18.85 18.5 19.5 17.7052 19.5H5.21579C4.5 19.5 4.5 18.775 4.5 18.425V17.375"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 9.875H18.4211V10.5"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.5 17.5C4.5 17.15 4.5 8.075 4.5 7.5C4.5 6.925 4.5 6 5.5 6C6.5 6 17.5 6 17.5 6C17.8579 6 18.5 6 18.5 7.075V10.5"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 5V8"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.5 5V8"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.84766 14.25L10.6371 16L14.574 12.0625"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5 17C20.1569 17 21.5 15.6569 21.5 14C21.5 12.3431 20.1569 11 18.5 11C16.8431 11 15.5 12.3431 15.5 14C15.5 15.6569 16.8431 17 18.5 17Z"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5 13V15"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5 14H19.5"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'AddToCalendarIcon',
  mixins: [IconsMixin]
};
</script>

<style scoped></style>
