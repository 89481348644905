<template>
  <div class="events-container">
    <EventMonthContainer v-if="listViewType === 'month'" />
    <EventAllContainer v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'EventsListView',
  components: {
    EventAllContainer: defineAsyncComponent(() => import('./List/EventAllContainer.vue')),
    EventMonthContainer: defineAsyncComponent(() => import('./List/EventMonthContainer.vue')),
  },
  computed: {
    ...mapState('events', ['listViewType', 'sections', 'selectedCalendars']),
  },
  mounted() {
    if (isEmpty(this.selectedCalendars)) {
      this.$store.commit(
        'events/initSelectedCalendars',
        this.sections.flatMap((item) => item.id),
      );
    }
  },
};
</script>
