<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26 23.333V24.933C26 25.4663 25.4667 25.9997 24.9333 25.9997H7.06667C6.53333 25.9997 6 25.4663 6 24.933V23.333"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 23.3337V12.667H26V23.3337"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 15.333V8.39967C6 7.86634 6.53333 7.33301 7.06667 7.33301H24.9333C25.4667 7.33301 26 7.86634 26 8.39967V15.333"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 6V10"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 6V10"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 12.667V15.3337"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0797 18.6667H14.921C14.794 18.6667 14.667 18.5397 14.667 18.4127V16.254C14.667 16.127 14.794 16 14.921 16H17.0797C17.2067 16 17.3337 16.127 17.3337 16.254V18.4127C17.3337 18.5397 17.2067 18.6667 17.0797 18.6667Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.0797 18.6667H18.921C18.794 18.6667 18.667 18.5397 18.667 18.4127V16.254C18.667 16.127 18.794 16 18.921 16H21.0797C21.2067 16 21.3337 16.127 21.3337 16.254V18.4127C21.3337 18.5397 21.2067 18.6667 21.0797 18.6667Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.0797 22.6667H18.921C18.794 22.6667 18.667 22.5397 18.667 22.4127V20.254C18.667 20.127 18.794 20 18.921 20H21.0797C21.2067 20 21.3337 20.127 21.3337 20.254V22.4127C21.3337 22.5397 21.2067 22.6667 21.0797 22.6667Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0797 22.6667H14.921C14.794 22.6667 14.667 22.5397 14.667 22.4127V20.254C14.667 20.127 14.794 20 14.921 20H17.0797C17.2067 20 17.3337 20.127 17.3337 20.254V22.4127C17.3337 22.5397 17.2067 22.6667 17.0797 22.6667Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'CalendarIcon',
  mixins: [IconsMixin]
};
</script>
