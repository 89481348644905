<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5857 12.4999L13.7928 6.70717L14.4999 6.00006L20.9999 12.4999L14.4999 19L13.7928 18.2929L19.5857 12.4999Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 12L20 12L20 13L4 13L4 12Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'EventLeftArrowIcon',
  mixins: [IconsMixin]
};
</script>
