<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    :fill="fillColor"
    xmlns="http://www.w3.org/2000/svg"
    :class="dropdownClass"
    @keydown.enter.exact.prevent="$emit('click')"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.7144 9L11.5003 14.3919L5.28389 9L4 10.4956L11.5003 17L18.9997 10.4956L17.7144 9Z"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'DropdownArrowIcon',
  mixins: [IconsMixin],
  props: {
    dropdownClass: {
      type: String,
      default: 'close'
    }
  }
};
</script>

<style scoped lang="scss">
.open {
  transform: rotate(-180deg);
  transition: all 0.5s ease-in-out;
}
.close {
  transition: all 0.5s ease-in-out;
}
</style>
