<template>
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.146447 0.146447C0.341709 -0.0488155 0.658292 -0.0488155 0.853554 0.146447L7.85355 7.14645C8.04882 7.34171 8.04882 7.65829 7.85355 7.85355C7.65829 8.04882 7.34171 8.04882 7.14645 7.85355L0.146447 0.853553C-0.0488151 0.658291 -0.0488151 0.341709 0.146447 0.146447Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.85355 0.146447C8.04882 0.341709 8.04882 0.658291 7.85355 0.853553L0.853553 7.85355C0.658291 8.04881 0.341709 8.04882 0.146447 7.85355C-0.0488156 7.65829 -0.0488156 7.34171 0.146447 7.14645L7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'EventLeftArrowIcon',
  mixins: [IconsMixin]
};
</script>
