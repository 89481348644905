<template>
  <div class="tag-tooltip tag-tooltip-bottom">
    <div
      v-if="displayText"
      :style="cssVars"
      :class="variant"
      class="event-tag"
    >
      {{ text }}
    </div>
    <div class="tag-tooltip-text">
      {{ displayText }}
    </div>
  </div>
</template>

<script>
const VARIANTS = ['regular', 'pill'];
const validate = (availableValues) => (propVal) => availableValues.includes(propVal);

export default {
  name: 'EventListTag',
  props: {
    displayText: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'regular',
      validator: validate(VARIANTS),
    },
    bgColor: {
      type: String,
      default: '#F3F3F3',
    },
    fontColor: {
      type: String,
      default: '#000',
    },
  },
  computed: {
    text() {
      if (this.variant === 'pill') {
        return this.displayText;
      }

      return `| ${this.displayText}`;
    },
    cssVars() {
      return {
        '--bg-color': this.bgColor,
        '--font-color': `${this.fontColor}CC`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.event-tag {
  height: fit-content;
  width: fit-content;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background: var(--bg-color);
  color: var(--font-color);
  font-weight: 400;
  cursor: default;
  text-transform: capitalize;
  margin-right: 8px;

  &.regular {
    margin-left: 4px;
    font-size: 16px;
    text-transform: uppercase;
    background: transparent;
  }

  &.pill {
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 14px;
  }
}

.tag-tooltip {
  position: relative;
  display: inline-block;

  .tag-tooltip-text {
    visibility: hidden;
    width: fit-content;
    max-width: 200px;
    background: #333333;
    text-align: center;
    border-radius: 3px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    font-style: normal;
    font-weight: 450;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.4px;
    color: #ffffff;

    transition: 0s visibility;

    &::after {
      content: '';
      position: absolute;
      border-width: 5px;
      border-style: solid;
    }
  }

  &:hover .tag-tooltip-text {
    visibility: visible;
    transition-delay: 1s;
  }

  &.tag-tooltip-bottom {
    .tag-tooltip-text {
      top: 120%;
      left: 10%;

      &::after {
        bottom: 100%;
        left: 50%;
        border-color: transparent transparent #2e2e2e transparent;
      }
    }
  }
}
</style>
