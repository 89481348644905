<template>
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.616793 1.04087C0.76324 0.894419 1.00068 0.894419 1.14712 1.04087L6.50696 6.4007L11.8668 1.04087C12.0132 0.894419 12.2507 0.894419 12.3971 1.04087C12.5436 1.18731 12.5436 1.42475 12.3971 1.5712L7.03729 6.93103L12.3971 12.2909C12.5436 12.4373 12.5436 12.6747 12.3971 12.8212C12.2507 12.9676 12.0132 12.9676 11.8668 12.8212L6.50696 7.46136L1.14712 12.8212C1.00068 12.9676 0.76324 12.9676 0.616793 12.8212C0.470346 12.6747 0.470346 12.4373 0.616793 12.2909L5.97663 6.93103L0.616793 1.5712C0.470346 1.42475 0.470346 1.18731 0.616793 1.04087Z"
      fill="#333333"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'CloseIcon',
  mixins: [IconsMixin]
};
</script>
