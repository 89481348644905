<template>
  <svg
    class="clock-icon"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 5.23809C4.5 5.38406 4.56378 5.52273 4.6746 5.61772L6.34127 7.04629C6.55093 7.22601 6.86658 7.20173 7.04629 6.99206C7.22601 6.7824 7.20173 6.46675 6.99206 6.28704L5.5 5.00813V2.91667C5.5 2.64052 5.27614 2.41667 5 2.41667C4.72386 2.41667 4.5 2.64052 4.5 2.91667V5.23809ZM9.5 5C9.5 7.48528 7.48528 9.5 5 9.5C2.51472 9.5 0.5 7.48528 0.5 5C0.5 2.51472 2.51472 0.5 5 0.5C7.48528 0.5 9.5 2.51472 9.5 5Z"
      stroke="#333333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'EventClockIcon',
  mixins: [IconsMixin]
};
</script>

<style scoped>
  .clock-icon {
    margin-left: 5px;
  }
</style>
