export default {
  props: {
    fillColor: {
      type: String,
      default: '#333'
    },
    strokeColor: {
      type: String,
      default: '#333'
    }
  }
};
