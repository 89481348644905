<template>
  <div class="tag-container tag-tooltip tag-tooltip-bottom">
    <div
      v-if="text"
      :style="cssVars"
      class="search-tag pill"
    >
      <span class="text">
        {{ text }}
      </span>
      <button
        class="icon"
        @click="onRemove"
      >
        <RemoveIcon />
      </button>
    </div>
  </div>
</template>

<script>
import RemoveIcon from '../../icons/RemoveIcon.vue';

export default {
  name: 'SearchTag',
  components: { RemoveIcon },
  props: {
    text: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: '#F3F3F3'
    },
    fontColor: {
      type: String,
      default: '#000'
    },
    onRemove: {
      type: Function,
      default: () => {}
    },
  },
  computed: {
    cssVars() {
      return {
        '--bg-color': this.bgColor,
        '--font-color': `${this.fontColor}CC`
      };
    }
  }
};
</script>

<style lang="scss" scoped>

.tag-container {
    height: 24px;
    padding: 8px;
    background-color: #fbfbfb;
    border: none;
    border-bottom: 1px solid #adadad;
    border-radius: 4px 4px 0 0;
    transition: 0.2s ease;
    color: var(--input-font-color);
    transition: background-color 0.5s;
}

.search-tag {
  height: fit-content;
  width: fit-content;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background: var(--bg-color);
  color: var(--font-color);
  font-weight: 400;
  cursor: default;
  text-transform: capitalize;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;

  .text {
    max-width: 125px;
    display: inline;
    width: 125px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .icon {
    cursor: pointer;
    border: none;
    svg {
      margin: auto;
    }
  }

  &.pill {
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 14px;
  }
}

.tag-tooltip {
  position: relative;
  display: inline-block;
  width: -webkit-fill-available;
  width: -moz-available;
  .tag-tooltip-text {
    visibility: hidden;
    width: fit-content;
    max-width: 200px;
    background: #333333;
    text-align: center;
    border-radius: 3px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    font-style: normal;
    font-weight: 450;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.4px;
    color: #FFFFFF;

    transition: 0s visibility;

    &::after {
      content: '';
      position: absolute;
      border-width: 5px;
      border-style: solid;
    }
  }

  &:hover .tag-tooltip-text {
    visibility: visible;
    transition-delay: 1s;
  }

  &.tag-tooltip-bottom {
    .tag-tooltip-text {
      top: 120%;
      left: 10%;

      &::after {
        bottom: 100%;
        left: 50%;
        border-color: transparent transparent #2e2e2e transparent;
      }
    }
  }
}
</style>
