<template>
  <div class="view-wrapper">
    <div class="events__container">
      <div
        v-if="events.length > 0"
        class="events__tabs"
      >
        <a-tabs
          ref="tabs"
          class="search-view-tabs"
          :show-status-count="true"
          :status-list="tabs"
          :selected="tabSelected"
          :highlights-color="headingColor"
          :current-focus-idx="currentFocusTab"
          @switch-tabs="changeTab"
          @focus-next="focusNext"
        />
      </div>
      <div class="events__list">
        <div
          v-if="!filteredEvents.length && events.length"
          class="events-no-results"
        >
          {{ $t(`events.no${searchTabs[tabSelected].value}Search`, { searchQuery: searchText }) }}
          <EventsSkeleton />
        </div>
        <template v-else>
          <EventListItem
            v-for="event in filteredEvents"
            :key="event.id"
            :event-data="event"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import ATabs from '../../../global/DSCL-copycats/Tabs.vue';
import EventListItem from './List/EventListItem.vue';
import EventsSkeleton from './shared/EventsSkeleton.vue';
// Utils
import { SEARCH_TAB_LIST } from '../helpers/events.helper';
import pageHelpers from '../../../../pages/page-mixins';
import { getEventSearchDateParams, getCurrentFormattedDate } from '../../../../helpers/events-state.helper';

export default {
  name: 'EventsSearchView',
  components: { ATabs, EventListItem, EventsSkeleton },
  mixins: [pageHelpers],
  data() {
    return {
      searchTabs: SEARCH_TAB_LIST(this.$intl),
      tabSelected: 0,
      currentFocusTab: 0,
      pageNoPastEvents: 1,
      pageNoUpcomingEvents: 1,
      bottom: false,
      events: [],
      upcomingEvents: [],
      pastEvents: [],
    };
  },
  computed: {
    ...mapState('events', [
      'searchText',
      'allEvents',
      'totalUpcomingEvents',
      'totalPastEvents',
      'selectedCalendars',
      'sections',
    ]),
    ...mapGetters({
      headingColor: 'getThemeHeadingColor',
    }),
    ...mapGetters('translation', ['getLocale']),
    filteredEvents() {
      if (this.tabSelected === 0) {
        return this.getEvents(true);
      }
      return this.getEvents();
    },
    tabs() {
      return this.searchTabs.map((tab) => {
        let events = [];
        if (tab.value === 'Upcoming') {
          events = this.totalUpcomingEvents;
        } else {
          events = this.totalPastEvents;
        }
        return {
          ...tab,
          count: events,
        };
      });
    },
    isMaxEvents() {
      if (this.tabSelected === 0) {
        return this.upcomingEvents.length < 20 * this.pageNoUpcomingEvents;
      }
      return this.pastEvents.length < 20 * this.pageNoPastEvents;
    },
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        this.nextPage();
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible();
    });
    this.events = this.allEvents;
    if (isEmpty(this.selectedCalendars)) {
      this.$store.commit(
        'events/initSelectedCalendars',
        this.sections.flatMap((item) => item.id),
      );
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', () => {
      this.bottom = this.bottomVisible();
    });
  },
  methods: {
    focusNext(idx) {
      let nextSelectedIdx = 0;
      if (idx < 0) {
        nextSelectedIdx = this.searchTabs.length - 1;
      } else if (idx === this.searchTabs.length) {
        nextSelectedIdx = 0;
      } else {
        nextSelectedIdx = idx;
      }
      document.getElementById(`tab-${this.searchTabs[nextSelectedIdx].value}`).focus();
      this.currentFocusTab = this.searchTabs[nextSelectedIdx].idx;
    },
    changeTab(tab) {
      this.tabSelected = tab;
    },
    getEvents(upcoming) {
      const currentDate = new Date();
      return this.events.filter(({ end_at, all_day }) => {
        const eventDate = new Date(end_at);
        if (upcoming) {
          return eventDate >= currentDate || (all_day && this.sameDay(currentDate, eventDate));
        }
        return eventDate < currentDate && !(all_day && this.sameDay(currentDate, eventDate));
      });
    },
    sameDay(d1, d2) {
      return (
        d1.getUTCFullYear() === d2.getUTCFullYear() &&
        d1.getUTCMonth() === d2.getUTCMonth() &&
        d1.getUTCDate() === d2.getUTCDate()
      );
    },
    async nextPage() {
      let pageNo;
      if (this.isMaxEvents) return;

      if (this.tabSelected === 0) {
        this.pageNoUpcomingEvents += 1;
        pageNo = this.pageNoUpcomingEvents;
      } else {
        this.pageNoPastEvents += 1;
        pageNo = this.pageNoPastEvents;
      }

      try {
        const { url } = this.$store.getters.getEventsApi(this.$axios.defaults.baseURL);
        const res = await this.$axios.get(url, {
          params: {
            ...this.$route.query,
            ...getEventSearchDateParams(this.tabSelected === 0 ? 0 : 6),
            ...(this.tabSelected !== 0 ? getCurrentFormattedDate() : { end_date: undefined }),
            paginate: true,
            page_no: pageNo,
            locale: this.getLocale,
          },
          cache: { ignoreCache: false },
        });
        this.bottom = false;

        if (this.tabSelected === 0) {
          this.upcomingEvents = [...this.upcomingEvents, ...res.data.events];
        } else {
          this.pastEvents = [...this.pastEvents, ...res.data.events];
        }
        this.events = [...this.events, ...res.data.events];
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../global/scss/mixins/breakpoints';

.a-tabs.search-view-tabs {
  justify-content: flex-start;
  margin-top: 24px;
  border-top: 1px solid rgba(0, 73, 144, 0.1);
  @include screen('xs') {
    :deep(.tab) {
      width: 180px;
    }
  }
}
.events-no-results {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.8px;
  margin-top: 40px;
}
</style>
