<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 10C8.77614 10 9 9.77614 9 9.5C9 9.22386 8.77614 9 8.5 9C8.22386 9 8 9.22386 8 9.5C8 9.77614 8.22386 10 8.5 10Z"
      fill="#333333"
    />
    <path
      d="M23.5 9.5H11.5"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.5 17C8.77614 17 9 16.7761 9 16.5C9 16.2239 8.77614 16 8.5 16C8.22386 16 8 16.2239 8 16.5C8 16.7761 8.22386 17 8.5 17Z"
      fill="#333333"
    />
    <path
      d="M23.5 16.5H11.5"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.5 23C8.77614 23 9 22.7761 9 22.5C9 22.2239 8.77614 22 8.5 22C8.22386 22 8 22.2239 8 22.5C8 22.7761 8.22386 23 8.5 23Z"
      fill="#333333"
    />
    <path
      d="M23.5 22.5H11.5"
      :stroke="strokeColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'ListViewIcon',
  mixins: [IconsMixin]
};
</script>
