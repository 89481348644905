<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.9415 8.6214H21.412V7.64569C21.412 6.78477 20.1767 6.78477 20.1767 7.64569V8.6214H13.0589V7.64569C13.0589 6.78477 11.7648 6.78477 11.7648 7.64569V8.6214H9.05884C8.4706 8.6214 8 9.08056 8 9.65451V22.9701C8 23.544 8.4706 24.0032 9.05884 24.0032H23.9415C24.5297 24.0032 25.0003 23.544 25.0003 22.9701V9.65451C25.0003 9.08056 24.5297 8.6214 23.9415 8.6214ZM11.7648 9.88409V10.9172C11.7648 11.7207 13.0589 11.7207 13.0589 10.9172V9.88409H20.1767V10.9172C20.1767 11.7207 21.412 11.7207 21.412 10.9172V9.88409H23.7062V12.4095H9.23532V9.88409H11.7648ZM9.23532 13.6147H23.7062V22.7405H9.23532V13.6147Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.1181 15.7388L15.3533 18.7233L13.8827 17.1163C13.4121 16.5423 12.5297 17.2885 13.0003 17.8624L14.8239 19.9286C15.0592 20.1582 15.4121 20.2156 15.6474 19.986L19.8828 16.6571C20.4711 16.1406 19.7652 15.2796 19.1181 15.7388Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'CalendarViewIcon',
  mixins: [IconsMixin]
};
</script>

<style scoped></style>
