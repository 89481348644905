<template>
  <div class="events-skeleton-container">
    <div class="overlay" />
    <div
      v-for="index in count"
      :key="index"
      class="events-skeleton-container__empty__skeleton"
    >
      <div class="events-skeleton-container__empty__skeleton__date" />
      <div class="events-skeleton-container__empty__skeleton__content">
        <div class="events-skeleton-container__empty__skeleton__content__line" />
        <div class="events-skeleton-container__empty__skeleton__content__line" />
        <div class="events-skeleton-container__empty__skeleton__content__line" />
        <div class="events-skeleton-container__empty__skeleton__content__line" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EventsSkeleton',
  props: {
    count: {
      type: Number,
      default: 4
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../../global/scss/mixins/breakpoints';

.events-skeleton-container {
  position: relative;
  &__empty__skeleton {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 24px 0;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 73, 144, 0.1);
    &__date {
      width: 40%;
      max-width: 100px;
      height: auto;
      background: #e1e1e1;
      border-radius: 8px;
      @include screen(">=sm") {
        width: 20%;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      width: 60%;
      @include screen(">=sm") {
        width: 75%;
      }
      @include screen(">=md") {
        width: 80%;
      }
      @include screen(">=xxl") {
        width: 90%;
      }
      &__line {
        height: 17px;
        background: #f3f3f3;
      }
      &__line:nth-child(1) {
        width: 50%;
        margin-bottom: 10px;
      }
      &__line:nth-child(2) {
        width: 80%;
        margin-bottom: 10px;
      }
      &__line:nth-child(3) {
        width: 60%;
        margin-bottom: 15px;
      }
      &__line:nth-child(4) {
        width: 15%;
      }
    }
  }
  .overlay {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 90%
    );
    opacity: 0.75;
    position: absolute;
    bottom: 0;
    height: 100%;
    pointer-events: none;
    right: 0;
    left: 0;
  }
}
</style>
