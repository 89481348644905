<template>
  <div class="events-toolbar-actions">
    <div class="actions-left">
      <button
        v-if="displayFiltersBtn"
        :class="[{ active: filtersExpanded }, 'filter-button']"
        :style="styleOverrides"
        @click="$emit('collapse-menu')"
      >
        <FilterIcon />
      </button>
    </div>
    <div
      :style="styleOverrides"
      class="actions-right"
    >
      <div
        v-if="currentView !== 'EventsSearchView'"
        class="actions-right-buttons"
      >
        <YearDropdown />
        <div
          v-if="!isMobile"
          class="list-views-container"
        >
          <button
            v-for="[key, meta] in listViews"
            :id="meta.componentName"
            ref="viewTypes"
            :key="key"
            :style="styleOverrides"
            :class="[{ active: currentView === meta.componentName }, 'view-button']"
            :data-cy="`change-view-${meta.componentName}`"
            @click="$emit('change-view', key, $event.currentTarget)"
          >
            <component :is="meta.iconName" />
            <p class="button-text">{{ meta.displayName }}</p>
          </button>
        </div>
        <div
          v-if="!isMobile && currentView === 'EventsListView'"
          :style="styleOverrides"
          class="list-toggle-container"
        >
          <div class="list-toggle">
            <button
              :class="{ selected: listViewType === 'all' }"
              @click="toggleView('all')"
            >
              {{ $t('events.filterUpcomingEvents') }}
            </button>
            <button
              :class="{ selected: listViewType === 'month' }"
              @click="toggleView('month')"
            >
              {{ $t('events.filterByMonth') }}
            </button>
          </div>
        </div>
      </div>
      <button
        v-else
        class="close-icon"
        @click="resetSearch"
      >
        <CloseIcon />
        <span class="sr-only">{{ $t('events.clearSearch') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
// Components
import FilterIcon from './icons/FilterIcon.vue';
import ListViewIcon from './icons/ListViewIcon.vue';
import CalendarViewIcon from './icons/CalendarViewIcon';
import CloseIcon from './icons/CloseIcon.vue';
import YearDropdown from './EventViews/shared/YearDropdown.vue';
// Helpers
import { EVENT_VIEW_LIST, getSpecificListOrCalView } from './helpers/events.helper';

export default {
  name: 'EventsToolbarActions',
  components: {
    CalendarViewIcon,
    ListViewIcon,
    FilterIcon,
    CloseIcon,
    YearDropdown,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    isMobile: Boolean,
    filtersExpanded: Boolean,
  },
  emits: ['collapse-menu', 'change-view'],
  data() {
    return {
      listViews: Object.entries(EVENT_VIEW_LIST(this.$intl)),
    };
  },
  computed: {
    ...mapState('events', ['filters', 'hasSections', 'queryParams', 'currentView', 'listViewType']),
    ...mapGetters({ headingColor: 'getThemeHeadingColor' }),
    styleOverrides() {
      const bgColor = `${this.headingColor}1A`;
      const bgColorActive = `${this.headingColor}0D`;
      const borderActive = `1px solid ${this.headingColor}`;
      const radius = '8px';

      return {
        '--button-primary-bg': bgColor,
        '--button-primary-bg-hover': bgColorActive,
        '--button-primary-text': '#333333',
        '--button-border-radius': radius,
        '--button-border-active': borderActive,
        '--button-outline': 'none',
      };
    },
    displayFiltersBtn() {
      return this.isMobile && (this.hasSections || this.filters.length);
    },
  },
  methods: {
    resetSearch() {
      this.$store.dispatch('events/updateSearchText', '');

      return navigateTo({
        query: {
          id: undefined,
          search: undefined,
        },
      });
    },
    async toggleView(view) {
      this.$store.commit('events/setListViewType', view);
      if (isEmpty(omit(this.queryParams, 'view')) || isEmpty(omit(this.$route.query, 'view'))) {
        return navigateTo({
          query: {
            view: getSpecificListOrCalView(this.$store.state.events),
          },
        });
      } else {
        return navigateTo({
          query: {
            ...this.$route.query,
            view: getSpecificListOrCalView(this.$store.state.events),
            ...(view === 'all'
              ? {
                  end_date: undefined,
                  start_date: undefined,
                  date: undefined,
                  search: undefined,
                }
              : {
                  ...this.queryParams,
                  view: getSpecificListOrCalView(this.$store.state.events),
                }),
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../global/scss/mixins/flex_mixin';
@import '../../global/scss/mixins/breakpoints';

.events-toolbar-actions {
  @include flex-row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  .actions-left,
  .actions-right {
    .a-button {
      &:focus {
        outline: none;
        border: 2px solid blue;
      }
    }
    @include flex-row;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
  .actions-right {
    .actions-right-buttons {
      @include flex-row;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  @media (min-width: $lg-min-width) {
    .actions-left {
      display: none;
    }
  }
  @include screen('<=sm') {
    .actions-left {
      .filter-button {
        background-color: var(--button-primary-bg);
        border-radius: var(--button-border-radius);
        border: none;
        height: max-content;
      }
      .active {
        border: var(--button-border-active);
        background-color: var(--button-primary-bg-hover);
      }
    }
    .actions-right {
      .actions-right-buttons {
        .view-header {
          margin-right: 10px;
        }
        .view-button {
          font-family: inherit;
          margin: 0 4px;
          background-color: var(--button-primary-bg);
          border-radius: var(--button-border-radius);
          border: none;
          height: max-content;
        }
        .active {
          border: var(--button-border-active);
          background-color: var(--button-primary-bg-hover);
        }
      }
    }
  }
  @include screen('>=md') {
    .actions-right {
      .actions-right-buttons {
        .list-views-container {
          display: flex;
        }
        .view-button {
          font-family: inherit;
          margin: 0 4px;
          background-color: var(--button-primary-bg);
          border-radius: var(--button-border-radius);
          border: none;
          height: max-content;
          display: flex;
          align-items: center;
          padding: 8px 13px;
        }
        .active {
          border: var(--button-border-active);
          background-color: var(--button-primary-bg-hover);
        }
      }
    }
  }
}

.button-text {
  @media (max-width: $lg-min-width) {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}

.close-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  height: 33px;

  svg {
    padding: 10px;
    background-color: var(--button-primary-bg-hover);
  }
  &:hover {
    cursor: pointer;
  }
}

.list-toggle-container {
  z-index: 0;
  pointer-events: none;
  flex-basis: 100%;
  margin-top: 24px;
  .list-toggle {
    pointer-events: auto;
    display: flex;
    align-items: center;
    float: right;
    margin-right: 4px;
    border-radius: 4px;
    border: 1px solid var(--button-primary-bg-hover);
    button {
      border: none;
      padding: 0 8px;
      margin: 8px;
      border-radius: 4px;
      background: inherit;
      font-size: 12px;
      height: 19px;
      &:hover {
        cursor: pointer;
      }
      &.selected {
        background: var(--button-primary-bg-hover);
      }
    }
  }
}
</style>
