<template>
  <svg
    class="events-chevron-back-icon"
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.10355 1.60355C5.29882 1.40829 5.29882 1.09171 5.10355 0.896447C4.90829 0.701185 4.59171 0.701184 4.39645 0.896447L5.10355 1.60355ZM0.999999 5L0.646446 4.64645C0.552678 4.74021 0.499999 4.86739 0.499999 5C0.499999 5.13261 0.552678 5.25978 0.646446 5.35355L0.999999 5ZM4.39645 9.10355C4.59171 9.29881 4.90829 9.29881 5.10355 9.10355C5.29882 8.90829 5.29882 8.59171 5.10355 8.39645L4.39645 9.10355ZM4.39645 0.896447L0.646446 4.64645L1.35355 5.35355L5.10355 1.60355L4.39645 0.896447ZM0.646446 5.35355L4.39645 9.10355L5.10355 8.39645L1.35355 4.64645L0.646446 5.35355Z"
      fill="#333333"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'ChevronBackIcon',
  mixins: [IconsMixin]
};
</script>

<style scoped>
.events-chevron-back-icon {
  margin-right: 5px;
}
</style>
