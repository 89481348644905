<template>
  <div
    ref="event-popup-container"
    v-click-outside="handleClickAway"
    :style="localColorOverrides"
    class="cal-event-popup"
    data-cy="event-calendar-popup"
    @keydown.esc.exact.prevent="handleKeyboardClose"
  >
    <div
      ref="event-popup-content"
      class="content"
      :class="{ 'large-event': largeEvent }"
    >
      <div
        class="title"
        @click="viewEvent"
      >
        {{ event.title }}
      </div>
      <div class="date">
        {{ event.readableDate }}
      </div>
      <div
        v-if="event.venue"
        class="venue"
      >
        {{ event.venue }}
      </div>
      <div
        v-linkify
        class="details"
        v-html="sanitizedEventDescription"
      />
      <div>
        <EventListTag
          v-for="tag in event.eventTags"
          :key="tag"
          :display-text="tag"
          variant="pill"
        />
      </div>
      <div
        :id="`open-button-${event.id}`"
        tabindex="0"
        :aria-controls="`add-buttons-${event.id}`"
        :aria-expanded="showCalendarButtons.toString()"
        role="button"
        class="actions"
        @click="toggleCalendarButtons"
        @keydown.enter.exact.stop="toggleCalendarButtons"
      >
        <AddToCalendarIcon />
        <p>{{ $t('events.addToCalendar') }}</p>
      </div>
      <div
        v-show="showCalendarButtons"
        :id="`add-buttons-${event.id}`"
        :aria-labelledby="`open-button-${event.id}`"
        class="button-container close-button-container"
      >
        <AddToCalendar
          v-for="(type, idx) in CALENDARS"
          :key="idx"
          :calendar-type="type"
          :event-data="event"
          :color-overrides="localColorOverrides"
        />
        <button
          class="close-button-container"
          @click="toggleCalendarButtons"
        >
          <CloseIcon />
          <span class="sr-only">{{ $t('events.hideButtons') }}</span>
        </button>
      </div>
    </div>
    <span class="arrow-up" />
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
import EventListTag from '../List/EventListTag';
import AddToCalendar from '../shared/AddToCalendar.vue';
import AddToCalendarIcon from '../../icons/AddToCalendarIcon';
import CloseIcon from '../shared/Close.vue';
import { CALENDARS, VIEW_TYPES } from '../../../../../helpers/calendar.helper';

export default {
  name: 'EventPopUp',
  components: {
    EventListTag,
    AddToCalendarIcon,
    AddToCalendar,
    CloseIcon,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    getGlobals: {
      type: Object,
      default: () => ({}),
    },
    tooltipPosition: {
      type: Object,
      default: () => ({}),
    },
    parentBoundingBox: {
      type: Object,
      default: () => ({}),
    },
    colorOverrides: {
      type: Object,
      default: () => ({}),
    },
    eventYPos: {
      type: Number,
      default: 0,
    },
    viewType: {
      type: String,
      default: '',
    },
  },
  emits: ['view-event', 'clicked-away', 'close-esc'],
  data() {
    return {
      CALENDARS,
      showCalendarButtons: false,
    };
  },
  computed: {
    flexDirection() {
      return 'flex-column';
    },
    localColorOverrides() {
      return {
        ...this.colorOverrides,
        ...this.tooltipPosition,
        '--button-outline': `${this.colorOverrides['--button-outline']}`,
        '--button-primary-bg': `${this.colorOverrides['--button-primary-bg']}0D`,
        '--button-primary-bg-hover': `${this.colorOverrides['--button-primary-bg-hover']}1A`,
      };
    },
    largeEvent() {
      return this.event.description?.length >= 500;
    },
  },
  created() {
    this.sanitizedEventDescription = DOMPurify.sanitize(this.event.description);
  },
  mounted() {
    this.setPopUpPosition();
  },
  methods: {
    viewEvent() {
      this.$emit('view-event', this.event.id);
    },
    setPopUpPosition() {
      this.$nextTick(() => {
        const eventPopupContainer = this.$refs['event-popup-container'];
        const eventPopupContentRect = this.$refs['event-popup-content'].getBoundingClientRect();
        const isOutOfBounds = this.isOutOfBounds(this.parentBoundingBox, eventPopupContentRect);

        if (this.viewType === VIEW_TYPES.WEEK) {
          eventPopupContainer.style.top = isOutOfBounds
            ? this.calculateTopStyleForLargeEvent(eventPopupContentRect)
            : `${this.eventYPos - eventPopupContainer.getBoundingClientRect().top}px`;
        } else if (isOutOfBounds) {
          eventPopupContainer.style.top = this.calculateTopStyleForLargeEvent(eventPopupContentRect);
        }
      });
    },
    calculateTopStyleForLargeEvent(rect) {
      if (window.innerWidth >= 1280) {
        const middleOfCalendar =
          (this.parentBoundingBox.bottom - this.parentBoundingBox.top) / 2 + this.parentBoundingBox.top;
        return `${rect.top > 0 ? this.parentBoundingBox.top : middleOfCalendar / 2}px`;
      }
      return `${this.parentBoundingBox.height / 2}px`;
    },
    isOutOfBounds(parentElement, childElement) {
      return (
        childElement.top + window.scrollY < parentElement.top ||
        childElement.bottom + window.scrollY > parentElement.bottom
      );
    },
    handleClickAway() {
      this.$emit('clicked-away');
    },
    toggleCalendarButtons() {
      this.showCalendarButtons = !this.showCalendarButtons;
    },
    handleKeyboardClose() {
      this.$emit('close-esc');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../global/scss/mixins/flex_mixin';
@import './../shared/_event-mixins.scss';

.cal-event-popup {
  @include flex-row;
  flex-direction: var(--tooltip-flex-direction);
  z-index: 99;
  position: absolute;
  justify-content: center;
  align-items: var(--tooltip-align-items);
  left: var(--tooltip-left-position);
  height: 100%;

  .content {
    @include elevation;
    background-color: #fff;
    border-radius: 2px;
    padding: 16px 12px;
    max-width: 300px;
    position: relative;
    width: 300px;
    margin-top: 4px;
    max-height: var(--tooltip-content-max-height);
    overflow: auto;
    &.large-event {
      max-width: 450px;
      width: 450px;
    }

    .title {
      @include base-font;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 12px;
      word-break: break-word;
      cursor: pointer;
    }

    .date {
      @include base-font;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .details :deep() {
      @include base-font;
      line-height: 18px;
      align-items: left;
      text-align: inherit;
      word-break: break-word;
      overflow-wrap: anywhere;
      :deep(a) {
        color: var(--links-color);
      }
    }

    .venue {
      @include base-font;
      line-height: 14px;
      display: flex;
      align-items: center;
      font-weight: 400;
    }
    .actions {
      @include flex-row;
      margin-top: 8px;
      p {
        @include base-font;
        line-height: 14px;
        display: flex;
        align-items: center;
        font-weight: 400;
        margin: auto 0;
      }
    }
    .button-container {
      @include flex-column;
      justify-content: stretch;
      align-items: center;
      align-content: center;
      width: 100%;
      & p.button-text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.4px;
      }
      .add-to-calendar-button {
        color: #333333;
      }
      .close-button-container {
        background: none;
        border: none;
        display: flex;
        justify-content: center;
        margin: 4px 0;
      }
    }
    .calendar-buttons {
      @include flex-column;
    }
  }
}

.arrow-up {
  width: 0;
  height: 0;
  position: relative;
  box-sizing: border-box;
  left: -5px;
  top: var(--tooltip-arrow-top);

  &::after {
    z-index: -10;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    border: 5px solid #fff;
    border-color: #fff transparent transparent #fff;
    transform: rotate(135deg);
    // Shadow
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  }

  &::before {
    z-index: 10;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    border: 5px solid #fff;
    border-color: #fff transparent transparent #fff;
    transform: rotate(-45deg);
  }
}
</style>
