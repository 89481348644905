<template>
  <svg
    class="events-chevron-next-icon"
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.896447 8.39645C0.701185 8.59171 0.701184 8.90829 0.896447 9.10355C1.09171 9.29882 1.40829 9.29882 1.60355 9.10355L0.896447 8.39645ZM5 5L5.35355 5.35355C5.44732 5.25979 5.5 5.13261 5.5 5C5.5 4.86739 5.44732 4.74022 5.35355 4.64645L5 5ZM1.60355 0.896448C1.40829 0.701185 1.09171 0.701185 0.896446 0.896448C0.701184 1.09171 0.701184 1.40829 0.896446 1.60355L1.60355 0.896448ZM1.60355 9.10355L5.35355 5.35355L4.64645 4.64645L0.896447 8.39645L1.60355 9.10355ZM5.35355 4.64645L1.60355 0.896448L0.896446 1.60355L4.64645 5.35355L5.35355 4.64645Z"
      fill="#333333"
    />
  </svg>
</template>

<script>
import IconsMixin from './icons.mixin';

export default {
  name: 'ChevronNextIcon',
  mixins: [IconsMixin]
};
</script>

<style scoped>
.events-chevron-next-icon {
  margin-right: 5px;
  float: right;
  position: absolute;
  right: 0;
  top: 4px;
}
</style>
