<template>
  <div class="event-cal">
    <Breadcrumbs :crumbs="crumbs" />
    <div class="event-cal-container">
      <div class="event-cal-sidebar">
        <EventsFiltersSelectors
          v-if="screenSize >= 1280"
          @move-focus="focusOnCurrentMonth"
        />
      </div>
      <div class="event-cal-content">
        <div class="event-cal-content-toolbar">
          <EventsToolbarHeader class="toolbar-header" />
          <EventsToolbarActions
            ref="toolbarActions"
            :is-mobile="isMobile"
            :filters-expanded="filtersVisible"
            @change-view="changeView"
            @collapse-menu="filtersVisible = !filtersVisible"
          />
        </div>
        <hr class="mobile-border" />
        <div
          v-if="filtersVisible && screenSize < 1280"
          class="event-cal-mobile-filters"
        >
          <EventsFiltersSelectors />
        </div>
        <div class="event-cal-content-wrapper">
          <LoadingIndicator v-if="loading" />
          <component
            :is="currentView"
            v-else
            ref="currentView"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getSpecificListOrCalView } from './components/helpers/events.helper.js';
import Breadcrumbs from '../global/Breadcrumbs.vue';
import LoadingIndicator from '../global/LoadingIndicator.vue';
import EventsToolbarHeader from './components/EventsToolbarHeader.vue';
import EventsToolbarActions from './components/EventsToolbarActions.vue';
import EventsFiltersSelectors from './components/EventsFiltersSelectors.vue';
import EventsSearchView from './components/EventViews/EventsSearchView.vue';
import EventsListView from './components/EventViews/EventsListView.vue';
import EventsCalendarView from './components/EventViews/EventsCalendarView.vue';
import screenWidthTracker from '../pages/forms/components/mixins/screen-width-tracker.js';

export default {
  name: 'EventListContainer',
  components: {
    EventsCalendarView,
    EventsListView,
    EventsFiltersSelectors,
    EventsToolbarActions,
    EventsToolbarHeader,
    Breadcrumbs,
    LoadingIndicator,
    EventsSearchView,
  },
  mixins: [screenWidthTracker],
  props: {
    loading: Boolean,
    extraCrumb: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      filtersVisible: true,
    };
  },
  computed: {
    ...mapState('events', ['currentView', 'queryParams']),
    ...mapState(['pathPrefix', 'globals']),
    ...mapGetters('events', ['getCurrentMonth']),
    crumbs() {
      const crumbs = [
        {
          name: this.globals.info.school_name,
          link: `${this.pathPrefix}/`,
        },
        { name: 'Events', link: `${this.pathPrefix}/events` },
      ];
      if (this.extraCrumb) {
        crumbs.push({
          name: this.extraCrumb.name,
          link: `${this.pathPrefix}/${this.extraCrumb.slug}`,
        });
      }
      return crumbs;
    },
    isMobile() {
      return this.screenSize < 601;
    },
  },
  watch: {
    isMobile(val) {
      if (val && this.currentView === 'EventsCalendarView') {
        this.$store.commit('events/changeEventView', 'LIST_VIEW');
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isMobile) {
        this.$store.commit('events/changeEventView', 'LIST_VIEW');
        return navigateTo({
          query: {
            ...this.$route.query,
            view: getSpecificListOrCalView(this.$store.state.events),
          },
        });
      }
    });
  },
  methods: {
    async changeView(viewName, { id }) {
      this.$store.commit('events/changeEventView', viewName);
      if (viewName === 'LIST_VIEW') {
        this.$store.dispatch('events/updateDateFilters', {
          month: this.getCurrentMonth,
          target: id || null,
        });
      }

      return navigateTo({
        query: {
          ...this.$route.query,
          id: undefined,
          view: getSpecificListOrCalView(this.$store.state.events),
        },
      });
    },
    focusOnCurrentMonth() {
      if (this.currentView === 'EventsListView' && this.queryParams.view === 'list-month') {
        const monthTabs = Array.from(this.$refs.currentView.$el.querySelectorAll('button')).filter(
          (button) => button.attributes['aria-selected'],
        );
        const activeMonthTab = monthTabs.find((tab) => tab.ariaSelected === 'true');
        activeMonthTab.focus();
      } else {
        const calendarViewButton = document.getElementById('EventsCalendarView');
        calendarViewButton.focus();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../global/scss/mixins/flex_mixin';
@import '../global/scss/mixins/breakpoints';

/* Page Layout */
.event-cal {
  min-height: 80vh;
  @include flex-column;

  .event-cal-container {
    @include flex-column;
    flex: 1;
    padding: 0 20px;
    margin-bottom: 40px;
    box-sizing: border-box;
  }

  .event-cal-sidebar {
    border-right: 1px solid rgba(0, 73, 144, 0.1);
    display: none;
  }

  .event-cal-content {
    flex: 1;
    @include flex-column;
    margin-left: 38px;

    .event-cal-content-toolbar {
      @include flex-column;
    }

    .event-cal-mobile-filters {
      @include flex-column;
    }

    .event-cal-content-wrapper {
      flex: 1;
      z-index: 0;
      @include flex-column;

      .event-cal__content {
        @include flex-column;
      }
    }
  }
  @include screen('xs') {
    .event-cal-content {
      margin-left: 0;
      hr.mobile-border {
        border: 1px solid #0e315c0d;
        width: 90vw;
        margin: 24px 0;
      }
      .event-cal-content-toolbar {
        .toolbar-header {
          @include flex-row;
          align-items: center;
        }
      }
    }
  }
  @include screen('sm') {
    .event-cal-content {
      margin: 0 12px;
    }
  }
  @include screen('md') {
    .event-cal-content {
      margin-left: 24px;
      padding-left: 0;
      margin-right: 10px;
      .event-cal-content-toolbar {
        @include flex-row;
        justify-content: space-between;
      }
    }
  }
  @include screen('>=lg') {
    .event-cal-container {
      @include flex-row;
      flex: 1;
      padding: 0 40px;
      box-sizing: border-box;
    }

    .event-cal-sidebar {
      @include flex-column;
      max-width: 220px;
      min-width: 220px;
      padding-top: 52px;
      padding-right: 40px;
    }

    .event-cal-content {
      padding-top: 20px;
      padding-left: 16px;

      .event-cal-mobile-filters {
        display: none;
      }

      .event-cal-content-toolbar {
        @include flex-row;
        justify-content: space-between;
      }
    }
  }
  @include screen('>=sm') {
    .mobile-border {
      display: none;
    }
  }
}
</style>
